body {
  margin: 0;
  /* Another Segoe font added for Safari */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'bebasFont';
  src: url(./fonts/BebasNeue-Regular.ttf);
}

/* Setting Segoe font for Safari */
/* @font-face {
  font-family: SegoeUI;
  src: url(./fonts/SegoeUI.ttf);
} */